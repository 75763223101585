import React, { lazy, Suspense } from 'react';
import { Box, Typography, Button } from '@mui/material';
import './hero.css';
import AppShowCase from './../assets/img/app-showcase.webp';

const Hero = () => {
  return (
    <div>
      <Box className="hero-container">
        <div className="text-container">
          <Typography  component="h1" className="hero-title">
            Vorstellungsgespräch-<br /><span style={{ fontSize: "1.3em" }}>Simulator</span>
          </Typography>
          <Typography component="h2" className="hero-subtitle">
            Authentisch und vorbereitet – Ihr Erfolgsgeheimnis für das nächste Vorstellungsgespräch.
          </Typography>
          <div className="hero-button-div">
            <Button className="hero-button" onClick={() => document.getElementById('auto-interview-id').scrollIntoView({ behavior: 'smooth' })}>
              🗣️ Jetzt ausprobieren
            </Button>
            <Button
              className="hero-ghost-button"
              onClick={() => window.location.href = '/howto'}
            >
              Mehr erfahren
            </Button>
          </div>        <Suspense fallback={<div>Loading...</div>}>
            
        </Suspense>
        </div>
        <div className="image-container">
          <img src={AppShowCase} alt="Vorstellungs gespräch vorbereitung simulator live display" width={AppShowCase.width} height={AppShowCase.height} />
        </div>
      </Box>
    </div>
  );
};

export default Hero;
