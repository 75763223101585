import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText, 
  Box, useMediaQuery, useTheme 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './header.css';
//import LanguageSwitcher from './apps/LanguageSwitcher';
import HeaderJobTools from './apps/HeaderJobTools';
import logoImage from './../assets/img/bewerbungsim-logo.webp';
//import { HashLink } from 'react-router-hash-link';
import StripePricingTable from './wichtiges/StripePricingTable';
import HeaderFeatures from './apps/HeaderFeatures';

const Header = ({to}) => {
  const [isTop, setIsTop] = useState(true);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
// const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const delayHashLinkScroll = (id) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }
  
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsTop(scrollTop === 0);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <AppBar className='header-comp'>

        <Toolbar>
        <Typography 
          component={Link} 
          to="/" 
          variant="h6" 
          className="header-logo" 
          style={{ textDecoration: 'none' }} 
          onClick={(event) => {
            if (window.location.pathname === '/') {
              event.preventDefault();
              window.scrollTo(0, 0);
            }
          }}
        >
          <img src={logoImage} alt="Bewerbungsim Logo" className="header-logo-image" style={{ width: '200px', height: '35px' }}  />
        </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <div  className="desktop-header-navigation">
                <HeaderFeatures />
                <ListItem className="header-menu-desktop" component={Link}  to="/anmelden">
                  <ListItemText primary="Preise" />
                </ListItem>
                <HeaderJobTools /> {/* BewerbungsTools expendable menue */}
                <ListItem className='menu-desktop' component={Link} to="/blog">
                  <ListItemText primary="Blog" />
                </ListItem>
              </div>
          <div className="header-navigation-stay">
            <Button component={Link} to="/anmelden" color="inherit" className='header-login-button' >
              Login
            </Button>
            <Button component={Link} to="/anmelden" color="inherit" className='header scrolled calltoaction'>
              Jetzt testen
            </Button>
          </div>
          <IconButton edge="end" aria-label="menu" onClick={toggleDrawer} className="header-menu-icon" style={{ margin: '5px' }}>
            <MenuIcon />
          </IconButton>
          <Drawer className='drawer-container' anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
            <List>
            <ListItem className='list-item-drawer' button component={Link} to="/interviewsimulator-info" onClick={toggleDrawer}>
                <ListItemText primary="Features" />
              </ListItem>
              <ListItem className='list-item-drawer-login'  button component={Link} to="/preise" onClick={toggleDrawer}>
                <ListItemText primary="Preise" />
              </ListItem>
            <ListItem className='list-item-drawer' button component={Link} to="/faq" onClick={toggleDrawer}>
                <ListItemText primary="F.A.Q" />
              </ListItem>
              <ListItem className='list-item-drawer' button component={Link} to="/blog" onClick={toggleDrawer}>
                <ListItemText primary="Blog" />
              </ListItem>
              <ListItem className='list-item-drawer' button component={Link} to="/about" onClick={toggleDrawer}>
                <ListItemText primary="About" />
              </ListItem>
              <HeaderJobTools /> {/* BewerbungsTools expendable menue */}
              <ListItem className='list-item-drawer-login'  button component={Link} to="/anmelden" onClick={toggleDrawer}>
                <ListItemText primary="Login" />
              </ListItem>
            {/*}  <LanguageSwitcher /> */}
            </List>
          </Drawer>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;