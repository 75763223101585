import React, { useEffect } from 'react';
import './StripePricingTable.css'; // Stellen Sie sicher, dass der Pfad zur CSS-Datei korrekt ist.


const StripePricingTable = () => {


  useEffect(() => {
    // Erstellen Sie ein Skript-Element
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    // Fügen Sie das Skript-Element zum Dokument hinzu
    document.body.appendChild(script);

    // Bereinigungsfunktion, um das Skript-Element zu entfernen, wenn die Komponente nicht mehr gerendert wird
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
    <div className="stripe-pricing-wrapper">
      <div className="stripe-pricing-content">
      <stripe-pricing-table pricing-table-id="prctbl_1OJ0VhCbDRvWlsdDtl3L1mYN"
        publishable-key="pk_live_51O2DxLCbDRvWlsdDR9UzD4KZpMgckyspm9JrzO3wDKFpCuOxpuHtWnBKd1n4A9S6fXkz07ECo8bt4wjaD6o7T8sE00fDDb2ppE">
        </stripe-pricing-table>
      </div>

    </div>

  </>
  );
};

export default StripePricingTable;