import React, { lazy, Suspense } from 'react';
import { Box } from '@mui/material';
import './main.css';
import Hero from './Hero';
//import HighlightSection from './HighlightSection';
import {Helmet} from "react-helmet";

import CallToAction from './CallToAction';
import FeatureBulletpoints from './FeatureBulletpoints';
import Divider from './apps/Divider';


const HowtoVideo = lazy(() => import('./apps/HowToVideo'));
const InterviewSim = lazy(() => import('../interviewsim/InterviewSim'));
const FaqMain = lazy(() => import('./FaqMain'));
const Spacer = lazy(() => import('./apps/Spacer'));
const StepDashboard = lazy(() => import('../interviewsim/StepDashboard'));
const FeatureSection = lazy(() => import('./FeatureSection'));
const FeatureSection2 = lazy(() => import('./FeatureSection2'));
const TestimonialSlider = lazy(() => import('./TestimonialSlider'));
const Referenzen = lazy(() => import('./Referenzen'));
const SendMail = lazy(() => import('./apps/sendMail'));

const Main = () => {
  return (
    <Box className="main-container">
      <Helmet>
        <link rel="canonical" href={`https://bewerbungsim.de/`} />
      </Helmet>
      <Hero />
        <Suspense fallback={<div>Loading ...</div>}>
          <Referenzen />
        </Suspense>
        <Suspense fallback={<div>Loading ...</div>}>
        <HowtoVideo />
        <FeatureSection />
        <TestimonialSlider />
        <FeatureSection2 />
        <FeatureBulletpoints />
        <CallToAction />
        <StepDashboard />
        <Spacer />
        <InterviewSim />
        <Spacer />
        <Divider />
        <FaqMain />
        <SendMail />
      </Suspense>
      
        {/*<HighlightSection />*/}
        {/* <UserJourney /> */}
    </Box>
  );
};

export default Main;