import React from 'react';
import './FeatureBulletpoints.css';

const features = [
  {
    bold: 'Interaktive Bewerbungsgespräche:',
    text: 'mit KI-Interviewpartnern'
  },
  {
    bold: 'Angepasste Interviewfragen:',
    text: 'basierend auf Jobposition und Branche'
  },
  {
    bold: 'Echtzeit-Feedback:',
    text: 'und Vorschläge zur Interviewleistung'
  },
  {
    bold: 'Zugang zu einer Bibliothek:',
    text: 'von Interviewressourcen und Tipps'
  },
  {
    bold: 'Verfolgung des Fortschritts:',
    text: 'und Analysen zur Verbesserung der Interviewvorbereitung'
  },
  ];
  
  const benefits = [
    {
      bold: 'Optimale Vorbereitung:',
      text: 'Sei bestens gerüstet für dein nächstes Vorstellungsgespräch durch Training mit realitätsnahen Simulationen.'
    },
    {
      bold: 'Besseres Selbstvertrauens:',
      text: 'Der KI-Interviewpartner hilft, Selbstvertrauen aufzubauenGewinne an Sicherheit und trete souverän bei jedem echten Interview auf.'
    },
    {
      bold: 'Flexibilität:',
      text: 'Übe wo und wann du willst – unsere Web-App ist immer für dich da.'
    },
    {
      bold: 'Personalisiertes Training:',
      text: 'Genieße ein maßgeschneidertes Übungserlebnis, das sich deiner Karrierestufe und Branche anpasst.'
    },
  ];
  

function FeatureBulletpoints() {
    return (
        <>
        <h2 className='h2-features-und-vorteile'>Features und Vorteile</h2>
        <div className="feature-bulletpoint-container">
            
          <div className="feature-bulletpoint-item border-bullet">
            <h2>Features</h2>
            <ul>
              {features.map((feature, index) => (
                <li key={index} className="feature-bulletpoint-list-item">
                  <strong>{feature.bold}</strong> {feature.text}
                </li>
              ))}
            </ul>
          </div>
          
          <div className="feature-bulletpoint-item color-background-bullet">
            <h2>Vorteile</h2>
            <ul>
            {benefits.map((benefit, index) => (
              <li key={index} className="feature-bulletpoint-list-item">
                <strong>{benefit.bold}</strong> {benefit.text}
              </li>
            ))}
          </ul>
          </div>
    
        </div>
        </>
      );
    }

export default FeatureBulletpoints;
