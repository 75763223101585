import React, { useState } from 'react';
import { Button, Popover, List, ListItem, ListItemText } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import './HeaderJobTools.css';

const HeaderJobTools = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const externalLinkSymbol = '\u2197';
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <ListItem className="header-menu-desktop" onClick={handleClick}>
            <ListItemText primary=" JobTools" />
            {open ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
        </ListItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onMouseLeave={handleMouseLeave}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="nav">
          <ListItem  component="a" href="/bewerbungsfragen-generator" rel="nofollow">
            <ListItemText primary="Bewerbungsfragen Generator" />
          </ListItem>
          <ListItem  component="a" href="https://homeofficecentral.de/bewerbungs-checker/" rel="nofollow">
            <ListItemText primary={`Bewerbungs Prüfer ${externalLinkSymbol}`}/>
          </ListItem>
          {/* Fügen Sie hier weitere Unterverbindungen hinzu */}
        </List>
      </Popover>
    </div>
  );
};

export default HeaderJobTools;
