import React from 'react';
import { Button } from '@mui/material';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import './CallToAction.css';

const CallToAction = () => {
  return (
    <Fade>
        <div className="call-to-action-container">
            <h2 className="call-to-action-title">Jetzt Bewerbungs-Simulator nutzen!</h2>
            <Link to="/anmelden" style={{ textDecoration: 'none' }}>
                <Button style={{ backgroundColor: "black" }} variant="contained" className="call-to-action-button">
                    Jetzt kostenlos testen
                </Button>
            </Link>
        </div>


    </Fade>
  );
};

export default CallToAction;