import React from 'react';
import { Button } from '@mui/material';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import './Divider.css';

const Divider = () => {
  return (
    <Fade>
        <div className="call-to-action-container">
            <h2 className="call-to-action-title">Häufig gestellte Fragen (F.A.Q)</h2>

        </div>


    </Fade>
  );
};

export default Divider;