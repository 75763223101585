import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import './Footer.css';
import { Pinterest, Facebook, YouTube } from '@mui/icons-material';
import PaymentProofImage from './../assets/img/payments-prooff.png';
import SslCertImage from './../assets/img/ssl_cert.webp';

const Footer = () => {
    return (
        <>
        
        <Box className="footer-container">
            <div className="footer-content">
                <div className="footer-section">
                        <p className='footer-title'>BewerbungSim.de</p>
                        <Typography variant="body2" className="footer-text">
                        Bereiten Sie sich optimal auf Ihr Bewerbungsgespräch vor. Mit dem Bewerbungsgesprächs Simulator zum beruflichen Erfolg!
                        </Typography>
                </div>
                <div className="footer-section">
                    <div className="footer-logo">
                        {/* SVG oder Bild für Ihr Logo einfügen */}
                    </div>
                    <p className='footer-title'>Vorstellungsgespräch Simulator</p>
                    <Typography variant="body2" className="footer-text">
                        <a href="/">Home</a>
                    </Typography>
                    <Typography variant="body2" className="footer-text">
                    <a rel="noopener" href="/faq">Wissenswertes: F.A.Q.</a>
                    <Typography variant="body2" className="footer-text">
                        <Link to="/dashboard">Member Dashboard</Link>
                    </Typography>

                    </Typography>
                </div>
                <div className="footer-section important">
                    <p className='footer-title'>WICHTIGES</p>
                    <img src={PaymentProofImage} alt="Payment Proof" className="footer-image" />
                    <img src={SslCertImage} alt="SSL Certificate" className="footer-image" />
                    <Typography variant="body2" className="footer-text">
                        <Link to="/datenschutz">Datenschutz</Link>
                    </Typography>
                    <Typography variant="body2" className="footer-text">
                        <Link to="/impressum">Impressum</Link>
                    </Typography>
                    <Typography variant="body2" className="footer-text">
                        <Link to="/contact">Kontakt</Link>
                    </Typography>
                </div>
                <div className="footer-section">
                <p className='footer-title'>Über Uns</p>

                    <Typography variant="body2" className="footer-text">
                        <Link to="/about">Über uns</Link>
                    </Typography>
                    <Typography variant="body2" className="footer-text">
                        Perfekte Vorbereitung mit den K.I. Tools 
                    </Typography>

                </div>
            </div>
        </Box>
        <div className='copyright-date-footer-wrapper'>
            <span className='copyright-date-footer'>Vorstellungsgespräch Simulator ©2024 Bewerbungsim.de</span>
        </div>
        <div className='social-footer-wrapper'>
            
            <span className='copyright-date-footer'>
                <a href="https://www.pinterest.de/homeofficecentral/" target="_blank" rel="noopener noreferrer">
                    <Pinterest className="social-icon-pinterest" />
                </a>
                <a href="https://www.facebook.com/homeofficeblog" target="_blank" rel="noopener noreferrer">
                    <Facebook className="social-icon-facebook" />
                </a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                    <YouTube className="social-icon-youtube" />
                </a>
            </span>
        </div>
        </>
    );
};

export default Footer;